












































































































































































































































































































































































































































































































































































































































































































import VueBase from '../../../VueBase'
import { Component, Prop } from 'vue-property-decorator'
import { formatTimestamp } from '@/utils/utils'
@Component({
  name: 'HookTable',
  filters: {
    formatTimestamp(date: number | any) {
      return formatTimestamp(date)
    },
  },
})
export default class HookTable extends VueBase {
  private defaultCheckedKeys: any = []
  private strategyTypeList: any = []
  private defaultProps: any = {
    children: 'type_value',
    label: 'vul_name',
  }
  private async strategyTypes() {
    this.loadingStart()
    const { status, msg, data } = await this.services.setting.strategyTypes()
    this.loadingDone()
    if (status !== 201) {
      this.$message.error(msg)
      return
    }
    this.strategyTypeList = data
      .map((item: any) => {
        item.strategy_id = item.level_name
        item.vul_name = item.level_name
        item.isCollapse = item.type_value?.length > 0
        item.type_value = item.type_value.map((ele: any) => {
          ele.isCollapse = false
          return ele
        })
        return item
      })
      .filter((item: any) => item.isCollapse)
  }

  private stack_blacklist_test: Array<string> = []
  private newRuleType: any = ''

  @Prop({ default: '0', type: String }) ruleType!: string
  @Prop({ default: 1, type: Number }) activeLanguage!: number
  @Prop({ default: '', type: String }) activeLanguageName!: string
  @Prop({
    default: function () {
      return true
    },
    type: Function,
  })
  getBase!: any
  keyword = ''
  rule_type = ''
  hookTypeDialog = false
  hookDialog = false
  hookType = {
    type: '1',
    name: '',
    short_name: '',
    enable: 1,
    language_id: this.activeLanguage,
  }

  private resetData() {
    this.keyword = ''
    this.currentPage = 1
    this.handleCurrentChange(1)
  }

  types = []
  multipleSelection = []
  handleSelectionChange(val: any) {
    this.multipleSelection = val
  }
  hook = {
    id: 0,
    type: '1',
    rule_type_id: '',
    rule_value: '',
    source: [{ relation: '', origin: '', param: '' }],
    target: [{ relation: '', origin: '', param: '' }],
    inherit: 'false',
    // ignore_internal: false,
    // ignore_blacklist: false,
    ignore_internal: 0,
    ignore_blacklist: 0,
    track: 0,
    tags: [],
    untags: [],
    command: '',
    system_type: '',
    rule_type_value: '',
  }
  relations = [
    { label: this.$t('views.hookPage.or'), value: '|' },
    { label: this.$t('views.hookPage.and'), value: '&' },
  ]
  origins = [
    { label: this.$t('views.hookPage.O'), value: 'O' },
    { label: this.$t('views.hookPage.R'), value: 'R' },
    { label: this.$t('views.hookPage.P'), value: 'P' },
  ]
  tableData = []
  pageSize = 20
  currentPage = 1
  total = 0
  tagsList = []

  splitAndIn(str: string, key: string) {
    const arr = str.split(key)
    for (let i = 0; i < arr.length; i++) {
      if (i % 2 != 0) {
        arr.splice(i, 0, key)
        i++
      }
    }
    return arr
  }

  makeParamsArr(arr: any) {
    function fmtParams(params: string, symb: string) {
      const objArr: { relation: string; origin: string; param: string }[] = []
      if (params[0] === 'P') {
        const paramsStr = params.substring(1, params.length)
        const paramsArr = paramsStr.split(',')
        paramsArr.forEach((item) => {
          if (objArr.some((i) => i.origin === 'P')) {
            objArr.push({ relation: '&', origin: 'P', param: item })
          } else {
            objArr.push({ relation: symb, origin: 'P', param: item })
          }
        })
      } else {
        objArr.push({ relation: symb, origin: params, param: '' })
      }
      return objArr
    }
    function fmtParamsArr(arr: any) {
      const source = []
      if (arr.length == 1) {
        source.push(...fmtParams(arr[0], ''))
      } else {
        source.push(...fmtParams(arr[1], arr[0]))
      }
      return source
    }
    const source = []
    const params = []
    let pre = 0
    for (let k = 0; k < arr.length; k++) {
      if (arr[k] == '|' || arr[k] == '&') {
        source.push(arr.slice(pre, k))
        pre = k
      }
      if (k == arr.length - 1) {
        source.push(arr.slice(pre, arr.length))
      }
    }
    for (let i = 0; i < source.length; i++) {
      params.push(...fmtParamsArr(source[i]))
    }
    return params
  }

  showInput() {
    this.inputVisible = true
    this.$nextTick(() => {
      ;(this.$refs.saveTagInput as any).focus()
    })
  }

  async editRow(row: any) {
    if (row.type == '3') {
      this.strategyTypes()
    }
    const sourceArr = this.splitAndIn(row.source, '|')
    for (let i = 0; i < sourceArr.length; i++) {
      if (sourceArr[i].indexOf('&') > -1) {
        const arr2 = this.splitAndIn(sourceArr[i], '&')
        sourceArr.splice(i, 1, ...arr2)
        i += arr2.length
      }
    }
    const source = this.makeParamsArr(sourceArr)

    const targetArr = this.splitAndIn(row.target, '|')
    for (let i = 0; i < targetArr.length; i++) {
      if (targetArr[i].indexOf('&') > -1) {
        const arr2 = this.splitAndIn(targetArr[i], '&')
        sourceArr.splice(i, 1, ...arr2)
        i += arr2.length
      }
    }
    const target = this.makeParamsArr(targetArr)
    this.hook.rule_value = row.value
    this.hook.id = row.id
    this.hook.inherit = row.inherit
    this.hook.source = source
    this.hook.target = target
    // this.hook.rule_type_id = this.newRuleType =='4'? row.rule_type_id:row.rule_type_name
    this.hook.rule_type_id = row.rule_type_id
    this.hookDialog = true
    this.hook.ignore_internal = row.ignore_internal == true ? 1 : 0
    this.hook.ignore_blacklist = row.ignore_blacklist == true ? 1 : 0
    this.hook.tags = row.tags
    this.hook.untags = row.untags
    this.hook.command = row.command
    this.hook.system_type = row.system_type
    this.stack_blacklist_test = row.stack_blacklist
    this.hook.rule_type_value = row.rule_type_name
    this.hook.track = row.track == 'true' ? 1 : 0
    this.newRuleType = row.type?.toString()
    // defaultCheckedKeys
    let treeCheckedKyes: Array<any> = []
    const ruleIds = row.rules_id || []
    ruleIds.map((item: any) => {
      treeCheckedKyes = treeCheckedKyes.concat(item.values)
    })
    this.defaultCheckedKeys = treeCheckedKyes
  }
  async getTypes() {
    this.loadingStart()
    const { status, msg, data } = await this.services.setting.ruleTypes({
      // type: this.ruleType,
      type: this.newRuleType,
      language_id: this.activeLanguage,
    })
    this.loadingDone()

    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    this.types = data
  }

  async deleteRule(row: any) {
    this.loadingStart()
    const { status, msg } = await this.services.setting.ruleDelete({
      rule_id: row.id,
    })
    this.loadingDone()
    row.visible = false
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    await this.getTable()
  }

  addSource(arr: any) {
    arr.push({ relation: '', origin: '', param: '' })
  }
  deleteSource(arr: any, key: number) {
    arr.splice(key, 1)
    arr[0].relation = ''
  }

  changeOrigin(obj: any) {
    if (obj.origin !== 'P') {
      obj.param = ''
    }
  }

  async changeStatusBatch(op: string) {
    let str = ''
    let cancelButtonText = '确认'
    let confirmButtonClass = ''
    switch (op) {
      case 'delete':
        str = this.$t('views.hookPage.del') as string
        cancelButtonText = '删除'
        confirmButtonClass = 'delete-btn'
        break
      case 'enable':
        str = this.$t('views.hookPage.on') as string
        break
      case 'disable':
        str = this.$t('views.hookPage.off') as string
        break
    }
    this.$msgbox({
      showCancelButton: true,
      cancelButtonText: '取消',
      confirmButtonText: cancelButtonText,
      cancelButtonClass: 'cancelButtonClass',
      confirmButtonClass: confirmButtonClass,
      showClose: false,
      dangerouslyUseHTMLString: true,
      message: `
        <div class="title">
          <i class="el-icon-warning icon"></i>
          ${this.$t('views.hookPage.changeOne')}${str}${this.$t(
        'views.hookPage.changeTwo'
      )}
        </div>
      `,
    })
      .then(async () => {
        if (this.multipleSelection.length === 0) {
          this.changeStatusAll(op)
          return
        }
        const ids = this.multipleSelection.map((item: any) => item.id)
        const { status, msg } = await this.services.setting.changeStatusBatch({
          ids: String(ids),
          op,
        })
        if (status !== 201) {
          this.$message.error(
            op == 'delete'
              ? '自定义规则删除失败，请重试'
              : '状态变更失败，请重试'
          )
          return
        }
        this.$message.success(
          op == 'delete' ? '自定义规则删除成功' : '状态变更成功'
        )
        await this.getBase()
        await this.getTable()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  async changeStatusAll(status: string) {
    this.loadingStart()
    const obj = await this.services.setting.changeStatus({
      scope: 'all',
      op: status,
      language_id: this.activeLanguage,
      // hook_rule_type: this.ruleType,
      hook_rule_type: this.newRuleType,
    })
    this.loadingDone()
    if (obj.status !== 201) {
      this.$message.error(
        status == 'delete'
          ? '自定义规则删除失败，请重试'
          : '状态变更失败，请重试'
      )
      return
    }
    this.$message.success(
      status == 'delete' ? '自定义规则删除成功' : '状态变更成功'
    )
    this.currentPage = 1
    await this.getBase()
    await this.getTable()
  }

  async changeStatus(row: any, status = '') {
    this.loadingStart()
    const obj = await this.services.setting.changeStatus({
      rule_id: row.id,
      op: status || (row.enable === 0 ? 'disable' : 'enable'),
    })
    this.loadingDone()
    if (obj.status !== 201) {
      this.$message.error(
        status ? '自定义规则删除失败，请重试' : '状态变更失败，请重试'
      )
      return
    }
    this.$message.success(status ? '自定义规则删除成功' : '状态变更成功')
    await this.getBase()
    await this.getTable()
  }

  clearHookType() {
    this.hookType = {
      // type: this.ruleType,
      type: this.newRuleType,
      name: '',
      language_id: this.activeLanguage,
      short_name: '',
      enable: 1,
    }
    this.hookTypeDialog = false
  }
  async enterHookType() {
    ;(this.$refs.typeForm as any)?.validate(async (validate: any) => {
      if (!validate) return
      this.loadingStart()
      this.hookType.type = this.newRuleType
      const { status, msg } = await this.services.setting.ruleTypeAdd(
        this.hookType
      )
      this.loadingDone()
      if (status !== 201) {
        this.$message.error('添加规则类型失败，请重试')
        return
      }
      this.$message.success('添加规则类型成功')
      await this.getTypes()
      this.clearHookType()
    })
  }
  private typeOptions = [
    {
      label: this.$t('views.hookPage.spreadType'),
      value: '1',
    },
    {
      label: this.$t('views.hookPage.contaminatedType'),
      value: '2',
    },
    {
      label: this.$t('views.hookPage.filterType'),
      value: '3',
    },
    {
      label: this.$t('views.hookPage.dangerType'),
      value: '4',
    },
  ]
  fmtType(type: any) {
    switch (type) {
      case 1:
        return this.$t('views.hookPage.spreadType')
      case 2:
        return this.$t('views.hookPage.contaminatedType')
      case 3:
        return this.$t('views.hookPage.filterType')
      case 4:
        return this.$t('views.hookPage.dangerType')
      case 5:
        return this.$t('views.hookPage.enterType')
    }
  }
  clearHook() {
    this.stack_blacklist_test = []
    this.hook = {
      id: 0,
      // type: this.ruleType,
      type: this.newRuleType,
      rule_type_id: '',
      rule_value: '',
      source: [{ relation: '', origin: '', param: '' }],
      target: [{ relation: '', origin: '', param: '' }],
      inherit: 'false',
      // ignore_internal: false,
      // ignore_blacklist: false,
      ignore_internal: 0,
      ignore_blacklist: 0,
      track: 0,
      tags: [],
      untags: [],
      command: '',
      system_type: '',
      rule_type_value: '',
    }
    this.newRuleType = ''
    this.defaultCheckedKeys = []
    this.strategyTypeList = []
    this.hookDialog = false
  }
  fmtParams(arr: any) {
    let pStr = ''
    let str = ''
    arr.forEach((item: any) => {
      if (item.origin === 'P' && !pStr) {
        pStr += `${item.relation}${item.origin}${item.param}`
      } else if (item.origin === 'P' && pStr) {
        pStr += `,${item.param}`
      } else {
        str += `${item.relation}${item.origin}`
      }
    })
    return str + pStr
  }
  inputValue = ''
  inputVisible = false
  handleClose(tag: string) {
    let stack_blacklist = this.stack_blacklist_test
    let index = stack_blacklist.indexOf(tag)
    stack_blacklist.splice(index, 1)
  }
  handleInputConfirm() {
    let stack_blacklist = this.stack_blacklist_test
    let inputValue = this.inputValue
    if (inputValue) {
      stack_blacklist.push(inputValue)
    }
    this.inputVisible = false
    this.inputValue = ''
  }
  // ;(this.$refs.tree as any)?.setCheckedKeys([...rights, ...this.defaultCheckedKeys])
  async enterHook() {
    const regExp = /^[\u4e00-\u9fa5a-zA-Z_-]{1,50}$/gi
    // {pattern:/^[\u4e00-\u9fa5a-zA-Z_-]{1,50}$/gi, message: '规则名称只能包含中文、字母、下划线、中划线且长度不能超过50'}
    if (!this.newRuleType) {
      this.$message.error('请选择规则类型！')
      return
    }
    if (this.newRuleType != 4) {
      if (!this.hook.rule_type_value) {
        this.$message.error('请输入规则名称！')
        return
      }
      if (!regExp.test(this.hook.rule_type_value)) {
        this.$message.error(
          '规则名称只能包含中文、字母、下划线、中划线且长度不能超过50个字符！'
        )
        return
      }
    } else {
      if (!this.hook.rule_type_id) { // 危险方法是下拉框
        this.$message.error('请选择规则类型！')
        return
      }
    }
    // todo 组装数据
    let treeCheckedKyes: any = {}
    const postRuleData: Array<any> = []
    if (this.newRuleType === '3') {
      // todo 过滤方法需要组装tree数据 level_id   strategy_id
      const allTreeNodes: any = (this.$refs.tree as any)?.getCheckedNodes(
        true,
        false
      );
      // console.log(allTreeNodes)
      for (let i = 0; i < allTreeNodes.length; i++) {
        const node = allTreeNodes[i]
        if (treeCheckedKyes[node.level_id]) {
          treeCheckedKyes[node.level_id].strategy_id.push(node.strategy_id)
        } else {
          treeCheckedKyes[node.level_id] = {
            strategy_id: [node.strategy_id],
          }
        }
      }

      for (let key in treeCheckedKyes) {
        postRuleData.push({
          level_type: Number(key),
          values: treeCheckedKyes[key].strategy_id,
        })
      }
    }

    if (this.hook.id) {
      this.loadingStart()
      const rule_source = this.fmtParams(this.hook.source)
      const rule_target: any = this.fmtParams(this.hook.target)
      const { status, msg } = await this.services.setting.modifyAdd({
        rule_id: this.hook.id,
        rule_type_id: !this.hook.rule_type_id ? -1 : this.hook.rule_type_id,
        // rule_type_id: this.hook.rule_type_id,
        rule_value: this.hook.rule_value,
        rule_target: rule_target || undefined,
        rule_source: rule_source,
        inherit: this.hook.inherit,
        // track: 'false',
        track: this.hook.track == 1 ? 'true' : 'false',
        language_id: this.activeLanguage,
        ignore_internal: this.hook.ignore_internal == 1 ? true : undefined,
        // ignore_blacklist: this.hook.ignore_blacklist,
        ignore_blacklist: this.hook.ignore_blacklist == 1 ? true : false,
        tags: this.hook.tags,
        untags: this.hook.untags,
        command: this.hook.command,
        // type: Number(this.ruleType),
        type: Number(this.newRuleType),
        stack_blacklist: this.stack_blacklist_test,
        rule_type_name: this.hook.rule_type_value,
        rules: postRuleData,
      })

      this.loadingDone()
      if (status !== 201) {
        this.$message({
          type: 'error',
          message: msg,
          showClose: true,
        })
        return
      }
      this.$message.success('自定义规则编辑成功')
      await this.getTable()
      this.clearHook()
    } else {
      this.loadingStart()
      const rule_source = this.fmtParams(this.hook.source)
      const rule_target = this.fmtParams(this.hook.target)
      const { status, msg } = await this.services.setting.ruleAdd({
        rule_type_id: !this.hook.rule_type_id ? -1 : this.hook.rule_type_id,
        rule_value: this.hook.rule_value,
        rule_target: rule_target || undefined,
        rule_source: rule_source,
        inherit: this.hook.inherit,
        // track: this.hook.track,
        track: this.hook.track == 1 ? 'true' : 'false',
        language_id: this.activeLanguage,
        // ignore_internal: this.hook.ignore_internal || undefined,
        // ignore_blacklist: this.hook.ignore_blacklist,
        ignore_internal: this.hook.ignore_internal == 1 ? true : undefined,
        ignore_blacklist: this.hook.ignore_blacklist == 1 ? true : false,
        tags: this.hook.tags,
        untags: this.hook.untags,
        command: this.hook.command,
        // type: Number(this.ruleType),
        type: Number(this.newRuleType),
        stack_blacklist: this.stack_blacklist_test,
        rules: postRuleData,
        rule_type_name: this.hook.rule_type_value,
      })

      this.loadingDone()
      if (status !== 201) {
        this.$message({
          type: 'error',
          message: msg,
          showClose: true,
        })
        return
      }

      this.$message.success('自定义规则添加成功')
      await this.getBase()
      await this.getTable()
      this.clearHook()
    }
  }

  openHookDialog() {
    this.hook = {
      id: 0,
      type: '1',
      rule_type_id: '',
      rule_value: '',
      source: [{ relation: '', origin: '', param: '' }],
      target: [{ relation: '', origin: '', param: '' }],
      inherit: 'false',
      ignore_internal: 0,
      ignore_blacklist: 0,
      track: 0,
      tags: [],
      untags: [],
      command: '',
      system_type: '',
      rule_type_value: '',
    }
    this.hookDialog = true
  }
  async getTable() {
    this.loadingStart()
    const { status, msg, data, page } =
      await this.services.setting.hookRuleList({
        page: this.currentPage,
        pageSize: this.pageSize,
        // type: this.ruleType,
        // type: this.newRuleType,
        // type: '',
        strategy_type: this.rule_type,
        language_id: this.activeLanguage,
        keyword: this.keyword,
      })
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    this.total = page.alltotal
    if (data.length === 0 && this.currentPage > 1) {
      this.currentPage--
      await this.getTable()
      return
    }
    const tableDataSource:any = []
    data.map((item: any) => { 
      let rules_name:Array<any> = []
      if (item.rules_name && Array.isArray(item.rules_name) && item.rules_name.length > 0) {
        item.rules_name.map((item1:any) => {
          rules_name = rules_name.concat(item1.values)
        })
      }
      const obj = {
        ...item,
        rules_name_str: rules_name.join(','),
      }
      tableDataSource.push(obj)
    });
    this.tableData = tableDataSource
  }
  handleSizeChange(val: number) {
    this.pageSize = val
    this.currentPage = 1
    this.getTable()
  }
  handleCurrentChange(val: number) {
    this.currentPage = val
    this.getTable()
  }
  async getEnumData() {
    const { status, msg, data } = await this.services.setting.getEnum()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    this.tagsList = data.tags
  }
  created() {
    // this.hookType.type = this.ruleType
    // this.hook.type = this.ruleType
    this.hookType.type = this.newRuleType
    this.hook.type = this.newRuleType
    this.getTable()
    // this.getTypes()
    this.getEnumData()
    this.strategyTypes()
  }

  changeRuleType(val: any) {
    this.newRuleType = val
    if (val == 4) {
      this.getTypes()
    }
    if (val == 3) {
      this.strategyTypes()
    }
  }
}
